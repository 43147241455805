import { Button, LinkProps } from '@chakra-ui/react'
import NextLink from 'next/link'
import { ReactNode } from 'react'

import { LINK_COLOR, LINK_COLOR_HOVER } from '../colorConstants'
import { useMarketingHeaderUIContext } from '../MarketingHeaderUIContext'
export const NavbarTextLink = ({
  href,
  children,
  ...rest
}: {
  href: string
  children: ReactNode
  rest?: LinkProps
}) => {
  const { mode } = useMarketingHeaderUIContext()

  return (
    <Button
      variant="subtle"
      px={0}
      fontWeight="500"
      fontSize={{ base: 'md', md: 'sm' }}
      w={{ base: '100%', md: 'auto' }}
      href={href}
      as={NextLink}
      color={LINK_COLOR[mode]}
      _hover={{
        textDecoration: 'none',
        color: LINK_COLOR_HOVER[mode],
      }}
      {...rest}
    >
      {children}
    </Button>
  )
}
