import {
  Button,
  Drawer,
  DrawerBody,
  DrawerContent,
  Image,
  Link,
  Spacer,
  Stack,
  useDisclosure,
} from '@chakra-ui/react'
import { faArrowRight } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { t, Trans } from '@lingui/macro'
import NextLink from 'next/link'

import { useGetLocalizedMarketingSiteUrls } from 'modules/marketing_pages/hooks/useGetLocalizedMarketingSiteLinks'
import { useUserContext } from 'modules/user'

import { MARKETING_HEADER_HEIGHT, MARKETING_SITE_LINKS } from '../../constants'

export type HeaderMode = 'dark' | 'light'

import { MarketingLanguageSwitcher } from '../MarketingLanguageSwitcher'
import { ToggleButton } from '../ToggleButton'
import { GAMMA_LOGO_FILE, HEADER_BG_COLOR, LINK_COLOR } from './colorConstants'
import { useMarketingHeaderUIContext } from './MarketingHeaderUIContext'
import { NavbarTextLink } from './shared/NavbarItems'

const NavItems = () => {
  const { pricingUrl } = useGetLocalizedMarketingSiteUrls()
  const { user, isUserLoading } = useUserContext()

  const HEADER_LINKS = [
    {
      text: <Trans>Pricing</Trans>,
      url: pricingUrl,
    },
    {
      text: <Trans>Careers</Trans>,
      url: MARKETING_SITE_LINKS.careers,
    },
    {
      text: <Trans>Login</Trans>,
      url: MARKETING_SITE_LINKS.login,
    },
  ]

  return (
    <Stack
      alignItems="center"
      alignContent="center"
      spacing={4}
      direction="column"
    >
      {HEADER_LINKS.map(({ text, url }, ind) => {
        return (
          <NavbarTextLink key={ind} href={url}>
            {text}
          </NavbarTextLink>
        )
      })}

      {user && !isUserLoading ? (
        <Button
          w="100%"
          as={Link}
          href="/"
          rightIcon={<FontAwesomeIcon icon={faArrowRight} />}
          textDecoration="none"
          variant="fancy"
          _hover={{ textDecoration: 'none' }}
        >
          <Trans>Go to app</Trans>
        </Button>
      ) : (
        <Button
          w="100%"
          href={MARKETING_SITE_LINKS.signup}
          variant="fancy"
          as={Link}
          textDecoration="none"
          _hover={{ textDecoration: 'none' }}
        >
          <Trans>Try for free</Trans>
        </Button>
      )}
    </Stack>
  )
}

export const MarketingHeaderToggleAndMenu = ({
  isOpen,
  onToggle,
  onClose,
}: {
  isOpen: boolean
  onToggle: () => void
  onClose: () => void
}) => {
  const { mode } = useMarketingHeaderUIContext()

  return (
    <>
      <ToggleButton
        isOpen={isOpen}
        onClick={onToggle}
        aria-label={isOpen ? t`Close menu` : t`Open menu`}
        display={{ base: 'inline-flex', lg: 'none' }}
        color={LINK_COLOR[mode]}
      />

      <Drawer placement="top" isOpen={isOpen} onClose={onClose}>
        <DrawerContent bg={HEADER_BG_COLOR[mode]}>
          <DrawerBody
            mt={MARKETING_HEADER_HEIGHT}
            p="4"
            bg={HEADER_BG_COLOR[mode]}
          >
            <Stack spacing="1" pb={8}>
              <NavItems />
            </Stack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  )
}

export const MarketingHeaderMobile = () => {
  const { mode } = useMarketingHeaderUIContext()
  const { homeUrl } = useGetLocalizedMarketingSiteUrls()
  const {
    isOpen: isMenuOpen,
    onToggle: onMenuToggle,
    onClose: onMenuClose,
  } = useDisclosure()

  return (
    <Stack
      direction="row"
      width="100%"
      justifyContent="space-between"
      alignItems="center"
      spacing={4}
      backgroundColor="transparent"
    >
      <Link href={homeUrl} aria-current="page" as={NextLink}>
        <Image
          w="100px"
          src={GAMMA_LOGO_FILE[mode]}
          loading="lazy"
          alt="Gamma logo"
        />
      </Link>
      <Spacer />
      {/* Hide the marketing language switcher when the menu os open, because the modal doesn't play well with the open menu */}
      {!isMenuOpen && <MarketingLanguageSwitcher />}
      <MarketingHeaderToggleAndMenu
        isOpen={isMenuOpen}
        onToggle={onMenuToggle}
        onClose={onMenuClose}
      />
    </Stack>
  )
}
